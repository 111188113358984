<template>
  <div>
   <div id="froala-editor"></div>
  </div>
</template>

<script>


import FroalaEditor from 'froala-editor'
import 'froala-editor/js/plugins.pkgd.min.js'
import 'froala-editor/css/froala_editor.pkgd.min.css'


export default {
  name: 'Home',

  props: ['value'],

   
  mounted() {

      const vue = this

    //var editor = 
    const editor = new FroalaEditor('#froala-editor', {
      key:"oc1F2vB1E1F1A1A1D7B6nE5D4H3B1A-21wA-13igC3E3G3A2B7A4D2F4B2D2==",
      attribution: false,
      height: 500,
      //useClasses: false,
     fontFamily: {
      "Phetsarath_OT, sans-serif": 'Phetsarath OT',
      "Roboto,sans-serif": 'Roboto',
    },
     lineHeights: {
      Default: '',
      '0.5': '.5',
      '0.75': '.75',
      Single: '1',
      '1.15': '1.15',
      '1.5': '1.5',
      Double: '2'
    },
     // buttons: ['uploadFile'],
      toolbarButtons: ['fullscreen', 'bold', 'italic', 'underline', 'textColor', 'backgroundColor' , 'strikeThrough', 'subscript', 'superscript', '|', 'color', 'lineHeight', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', '-', 'insertLink', 'insertImage', 'insertVideo', 'insertFile', 'insertTable', '|', 'emoticons', 'specialCharacters', 'insertHR', 'selectAll', 'clearFormatting', '|', 'html'],
      imageUploadParam: 'image',

      // Set the image upload URL.
      imageUploadURL: `${this.$urlEditorImage}`,

      // Additional upload params.
    //  imageUploadParams: {id: 'froala-editor'},
      // Set request type.
      imageUploadMethod: 'POST',

      // Set max image size to 5MB.
      imageMaxSize: 10 * 1024 * 1024,

      // Allow to upload PNG and JPG.
      imageAllowedTypes: ['jpeg', 'jpg', 'png'],

    //  toolbarButtons: ['bold', 'italic', 'textColor', 'backgroundColor'],
  // Colors list.
  colorsBackground: [
    '#15E67F', '#E3DE8C', '#D8A076', '#D83762', '#76B6D8', 'REMOVE',
    '#1C7A90', '#249CB8', '#4ABED9', '#FBD75B', '#FBE571', '#FFFFFF'
  ],
  colorsStep: 6,
  colorsText: [
    '#15E67F', '#E3DE8C', '#D8A076', '#D83762', '#76B6D8', 'REMOVE',
    '#1C7A90', '#249CB8', '#4ABED9', '#FBD75B', '#FBE571', '#FFFFFF'
  ],

      imageResizeWithPercent: true,
      imageMinWidth: 100,

    events: {
      initialized: function () {
         const thisFunc = this
         thisFunc.html.set(vue.value)
      //  const html = thisFunc.html.get()
      //  vue.$emit('input', html)
      },
      contentChanged: function () {
          const thisFunc = this
          const html = thisFunc.html.get()
        //  vue.$emit('value', html)
          vue.$emit('input', html)
      },

      'image.beforeUpload': function() {
          editor.opts.requestHeaders.Authorization = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MTkxYzY2ZjljMmUyY2E0MDE5ZGU5NGYiLCJpYXQiOjE2NDgwMjU3MjEsImV4cCI6MTc0MjYzMzcyMX0.V2ySwRySOsRsOJcGaCRf4TaKs13QWRz_kgIwjnkk5EM'
      },
      'image.uploaded': function (response) {

        const imageUrl = JSON.parse(response).fileSrc



        editor.image.insert(imageUrl, false, null, editor.image.get(), imageUrl)

        return false
      },
    }
    
  
    })



    
  }
};
</script>


