<template>
  <div
    class="select-container"
    v-click-outside="() => (showSelect = false)"
    :class="{ active: showSelect }"
  >
    <div
      v-if="single"
      class="select-placeholder"
      :class="{ filtered: itemSelected.length, active: showSelect }"
      @click="showSelect = !showSelect"
    >
      <p v-if="foundName">{{ foundName }}</p>
      <p v-else>Select...</p>
    </div>

    <div v-else @click="showSelect = !showSelect" class="selected-item">
      <p v-for="i in itemSelected" :key="i">
        {{ getName(i) , }}
      </p>
    </div>

    <div v-if="showSelect" class="select-list-container">
      <div class="select-list-search">
        <input
          type="text"
          v-model="searchText"
          class="input"
          :placeholder="name"
        />
      </div>

      <div class="select-list">
        <div class="selected-list-item" v-if="itemSelected.length">
          <p v-for="(i, index) in itemSelected" :key="index">
            <span
              >{{ getName(i) }}
              <i
                class="fal fa-times-square"
                @click="itemSelected.splice(index, 1)"
              ></i
            ></span>
          </p>
          <p v-if="itemSelected.length >= 3" class="alert">
            Please select a maximum of 3 items
          </p>
        </div>

        <div class="select-list-item">
          <p
            @click="selectItem(item)"
            v-show="itemSelected.every((i) => i !== item)"
            v-for="(item, index) in filterItems"
            :key="index"
          >
            {{ item[itemName] }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
export default {
  props: {
    itemName: {
      type: String,
      default: "name",
    },
    itemId: {
      type: String,
      default: "_id",
    },
    items: {
      type: Array,
    },
    mutiple: {
      type: Boolean,
      default: true,
    },
    single: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "search...",
    },
    value: {
      type: Array,
    },
  },
  data: () => ({
    showSelect: false,
    itemSelected: [],
    searchText: "",
  }),
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    filterItems() {
      return this.items.filter((i) => {
        return (
          !this.itemSelected.includes(i["_id"]) &&
          i[this.itemName].toLowerCase().match(this.searchText.toLowerCase())
        );
      });
    },
    getName() {
      return (id) => {
        const item = this.items.find((i) => i._id === id);

        return item ? item.name : "";
      };
    },

    foundName() {
      const found = this.items.find((i) => {
        return i[this.itemId] === this.value;
      });

      return found != undefined ? found[this.itemName] : "";
    },
  },
  watch: {
    value(val) {
      this.itemSelected = val;
    },
    items(val) {
      if (this.$route.query[this.routeQuery]) {
        let route = this.$route.query[this.routeQuery];
        if (this.single) {
          let item = val.filter((item) => {
            return item._id === route;
          });
          this.itemSelected = item;
          this.$emit("input", route);
        } else {
          if (typeof route === "string") {
            let item = val.filter((item) => {
              return item._id === route;
            });
            this.itemSelected = item;
            this.emitToInput(item);
          } else {
            let items = route.map((obj) => {
              return val.find((item) => {
                return item._id === obj;
              });
            });
            this.itemSelected = items;
            this.emitToInput(items);
          }
        }
      } else {
        this.itemSelected = [];
      }
    },
  },
  methods: {
    selectItem(item) {
      if (this.single) {
        this.itemSelected[0] = item;
        this.emitToInput(item);
        this.close();
      } else {
        if (this.itemSelected.includes(item._id)) {
          const foundIdx = this.itemSelected.findIndex((i) => i === item._id);

          this.itemSelected.splice(foundIdx, 1);
        } else if (this.itemSelected.length < 3) {
          this.itemSelected.push(item._id);
        } else {
          //  alert('3 Only Okay !?')
        }
        this.emitToInput(this.itemSelected);
      }
    },
    removeItem(index) {
      if (this.single) {
        this.itemSelected = [];
        this.$emit("input", "all");
        this.close();
      } else {
        this.itemSelected.splice(index, 1);
        this.emitToInput(this.itemSelected);
      }
    },
    close() {
      this.showSelect = false;
    },
    emitToInput(val) {
      this.$emit("input", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.error {
  border: 2px solid var(--alert-color);
  color: var(--alert-color);
}
.select-container {
  position: relative;
  .select-placeholder {
    background-color: #fff;
    border: 1px solid var(--primary-color);
    display: flex;
    font-size: 1rem;
    height: 2.5em;
    justify-content: flex-start;
    line-height: 1.5;
    padding-bottom: calc(0.5em - 1px);
    padding-left: calc(0.75em - 1px);
    padding-right: calc(0.75em - 1px);
    padding-top: calc(0.5em - 1px);
    cursor: pointer;
  }
  .selected-item {
    overflow: hidden;
    display: flex;
    gap: 5px;
    background-color: #fff;
    border: 1px solid var(--primary-color);
    display: flex;
    font-size: 1rem;
    height: 2.5em;
    justify-content: flex-start;
    line-height: 1.5;
    padding-bottom: calc(0.5em - 1px);
    padding-left: calc(0.75em - 1px);
    padding-right: calc(0.75em - 1px);
    padding-top: calc(0.5em - 1px);
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    span {
      display: inline-block;
      white-space: nowrap;
    }
  }
  .select-list-container {
    position: absolute;
    top: 0;
    background-color: #fff;
    z-index: 999;
    width: 100%;
    border: 1px solid var(--border-color);
    .select-list-search {
      padding: 8px 10px;
    }
    .select-list {
      max-height: 300px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .selected-list-item {
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        span {
          white-space: pre;
          background-color: var(--primary-color);
          display: inline-block;
          color: #fff !important;
          padding: 5px 10px !important;
        }
      }
      .select-list-item {
        overflow-y: auto;
        p {
          padding: 8px 10px;
          cursor: pointer;
          &:hover {
            background-color: var(--primary-color);
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
