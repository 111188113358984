import gql from 'graphql-tag'

export const GET_REUSE =  gql`
query(
    $types: String!
  ) 
  {
    companyReuse(types: $types){
      
      _id
      name
      
      laoName
      
    }
    
  },
  
`
export const GET_PROVINCES = gql `
{
  getProvinces {
    _id
    name
    laoName
    districts{
      _id
      name
      laoName
    }
  }
}
`
export const GET_NEW_JOB_FUNCTION = gql`
 query (
  $search: String
  ) {	
    getParents(search:$search) {
		_id
		name
		laoName
		score
    isCheck
    status
		items {
			_id
			name
			laoName
      isCheck
			score
		}
	}
}
`